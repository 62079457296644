import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text, Modal } from "react-native";
import { t } from "react-native-tailwindcss";
import styled from "styled-components/native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useToast } from "react-native-toast-notifications";
import Button from "../../../atoms/Button";
import Input from "../../../molecules/Input";
import { useUser } from "../../../../context/userContext";
import { AppScreensParamList } from "../../../../navigation/app-screens";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { HCenterStack, HStack } from "../../../layout/HStack";
import { LargeText } from "../../../atoms/LargeText";
import { MediumText } from "../../../atoms/MediumText";
import Currency from "../../../atoms/Currency";
import { LinkText } from "../../../atoms/LinkText";
import usePayments from "../../../../hooks/usePayments";
import { ApiError, getErrorMessage } from "../../../../services/api/api";
import { Spinner } from "../../../organisms/Spinner";
import { usePayFixedAmountMutation } from "../../../../hooks/mutations/usePayFixedAmountMutation";

const StyledButtonsContainer = styled(HStack)`
  justify-content: space-between;
`;

const StyledHalfWidthButtonContainer = styled(HStack)`
  width: 49%;
`;

type FormData = {
  amount: string;
};

type CustomErrors = {
  amount?: boolean;
  amountFormat?: boolean;
  amountZero?: boolean;
  general?: string;
};

export const PayNowScreen = (): React.ReactElement => {
  const toast = useToast();
  const { user } = useUser();
  const { mutateAsync: payFixedAmount } = usePayFixedAmountMutation();
  const { totalClearedAndPending } = usePayments();
  const [modalVisible, setModalVisible] = useState(false);
  const [fixedPaymentAmount, setFixedPaymentAmount] = useState(0);
  const [remainingContributionAmount, setRemainingContributionAmount] =
    useState<number | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user?.contributionLimit) {
      const remaining = user.contributionLimit.limit - totalClearedAndPending;
      setRemainingContributionAmount(remaining);
    }
  }, [totalClearedAndPending, user]);

  const navigation = useNavigation<NavigationProp<AppScreensParamList>>();
  const [customErrors, setCustomErrors] = useState<CustomErrors>({});
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { amount: "" } as FormData,
  });

  const runCustomValidation = (amount: string) => {
    let isValid = true;
    const newCustomErrors: CustomErrors = {
      ...customErrors,
      general: undefined,
    };

    // is amount not a number in string form?
    if (Number.isNaN(Number(amount))) {
      newCustomErrors.amountFormat = true;
      isValid = false;
    } else {
      newCustomErrors.amountFormat = false;
    }

    // is number greater than balance remaining
    if (
      !Number.isNaN(Number(amount)) &&
      remainingContributionAmount &&
      // need to compare amount in cents
      Number(amount) * 100 > remainingContributionAmount
    ) {
      newCustomErrors.amount = true;
      isValid = false;
    } else {
      newCustomErrors.amount = false;
    }

    // is number 0
    if (Number(amount) === 0) {
      newCustomErrors.amountZero = true;
      isValid = false;
    } else {
      newCustomErrors.amountZero = false;
    }

    setCustomErrors(newCustomErrors);
    return isValid;
  };

  const onSubmit = ({ amount }: FormData): void => {
    const isValid = runCustomValidation(amount);
    if (!isValid) {
      return;
    }

    // set fixed amount to pay in cents
    setFixedPaymentAmount(Number(amount) * 100);
    setModalVisible(true);
  };

  const payNow = async (): Promise<void> => {
    setLoading(true);
    try {
      await payFixedAmount(fixedPaymentAmount);
      toast.show("Success! Thanks for making a donation", {
        type: "success",
      });
      navigation.navigate("Dashboard");

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: Error & ApiError) {
      setCustomErrors({
        general: getErrorMessage(error),
      });
    } finally {
      setModalVisible(false);
      setLoading(false);
    }
  };

  return (
    <ScreenContainer>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <MediumText>
            Please enter an amount you'd like to donate. This amount will be
            debited from your{" "}
            <LinkText onPress={() => navigation.navigate("FundingAccount")}>
              Funding Account.
            </LinkText>
          </MediumText>
          {user?.contributionLimit && (
            <MediumText>
              Your remaining amount until you reach your target (excluding your
              pending payments) is{" "}
              <Currency cents={remainingContributionAmount ?? 0} />
            </MediumText>
          )}
          <Controller
            name="amount"
            control={control}
            render={({ onChange, value }) => (
              <Input
                error={errors.amount ? "This is required." : undefined}
                onChangeText={(text: string) => onChange(text)}
                value={value}
                type="numeric"
                placeholder="Amount"
              />
            )}
            rules={{
              required: true,
            }}
          />
          {customErrors.amountFormat && (
            <Text style={styles.error}>Please enter a number.</Text>
          )}
          {customErrors.amount && (
            <Text style={styles.error}>
              Please enter an amount equal to or less than the amount remaining
              to reach your payment target
            </Text>
          )}
          {customErrors.amountZero && (
            <Text style={styles.error}>Amount can't be zero</Text>
          )}

          <HCenterStack style={[t.pT2]}>
            <View style={t.wFull}>
              <Button onPress={handleSubmit(onSubmit)} label="Submit" />
            </View>
          </HCenterStack>

          {customErrors.general && (
            <Text style={[styles.error, t.textCenter, t.mT2]}>
              {customErrors.general}
            </Text>
          )}

          <Modal
            animationType="slide"
            visible={modalVisible}
            presentationStyle="fullScreen"
          >
            <SafeAreaView>
              <ScreenContainer center>
                <LargeText center>Are you sure?</LargeText>
                <MediumText center>
                  This will immediately debit your Funding Account{" "}
                  <Currency cents={fixedPaymentAmount} />
                </MediumText>
                <StyledButtonsContainer style={[t.pT4]}>
                  <StyledHalfWidthButtonContainer>
                    <Button
                      // disabled={loading}
                      onPress={() => setModalVisible(false)}
                      colour="gray"
                      label="Cancel"
                    />
                  </StyledHalfWidthButtonContainer>
                  <StyledHalfWidthButtonContainer>
                    <Button
                      // disabled={loading}
                      colour={loading ? "gray" : undefined}
                      onPress={payNow}
                      label="Confirm"
                    />
                  </StyledHalfWidthButtonContainer>
                </StyledButtonsContainer>
              </ScreenContainer>
            </SafeAreaView>
          </Modal>
        </>
      )}
    </ScreenContainer>
  );
};

const styles = {
  error: [t._mT1, t.mB2, t.textRed500],
};
